// React imports
import type { ReactElement } from 'react'
import React, { useRef } from 'react'

// Remix imports

// Third party imports
import { AnimatePresence, motion } from 'framer-motion'

// Generated imports

// App imports
import useCarousel from '~/hooks/useCarousel'

interface Props {
  /** Defined in tailwind.config.js */
  aspectRatio: 'md:aspect-1110/448' | 'md:aspect-1110/300'
  aspectRatioMobile?: 'aspect-712/876'
  className?: string
  slides: ReactElement[]
  renderDots?: boolean
  interval?: number
}

const Carousel: React.FC<Props> = ({
  aspectRatio,
  aspectRatioMobile = '',
  className,
  slides,
  renderDots,
  interval,
}) => {
  /**
   * Base Carousel component. Shows new slides with a
   * sliding-in while also blending-in animation.
   *
   */
  const carouselContainer = useRef(null)
  const { currentSlide, pauseSlider, resumeSlider, setSlide } = useCarousel(
    slides.length,
    carouselContainer,
    interval ? interval : 8000
  )

  const currentSlideElement = slides[currentSlide]
  const slideIndexes = [] as [number, string][]
  slides.forEach((_, index) =>
    slideIndexes.push([index, (index + 1).toString().padStart(2, `0`)])
  )

  const renderSlideIndexes = () => {
    if (slideIndexes.length <= 1) return null

    return (
      <div className="absolute top-[20px] left-[30px] flex gap-4">
        {slideIndexes.map(([index, displayValue]) => {
          const isActive = index === currentSlide
          return (
            <span
              key={index}
              className={
                'cursor-pointer font-serif text-h2 text-white' +
                (isActive ? ' opacity-100' : ' opacity-50')
              }
              onClick={() => setSlide(index)}
            >
              {displayValue}
            </span>
          )
        })}
      </div>
    )
  }

  const renderSlideDots = () => {
    if (slideIndexes.length <= 1) return null

    return (
      <div className="mt-4 flex justify-center gap-4">
        {slideIndexes.map(([index]) => {
          const isActive = index === currentSlide
          return (
            <span
              key={index}
              className={
                'h-3 w-3 cursor-pointer rounded-full bg-gray-bg-2' +
                (isActive ? ' !bg-brand-gold-dark' : '')
              }
              onClick={() => setSlide(index)}
            />
          )
        })}
      </div>
    )
  }

  return (
    <div>
      <div
        className={
          `${aspectRatio} ${aspectRatioMobile} relative w-full overflow-hidden` +
          (className ? ` ${className}` : '')
        }
        ref={carouselContainer}
        onMouseEnter={() => pauseSlider()}
        onMouseLeave={() => resumeSlider()}
      >
        <AnimatePresence initial={false}>
          <motion.div
            style={{
              display: 'block',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
            key={currentSlide}
            transition={{ duration: 0.5 }}
            initial={{ position: 'absolute', opacity: 0, left: '10px' }}
            animate={{ position: 'relative', opacity: 1, left: '0' }}
            exit={{ position: 'absolute', opacity: 0 }}
          >
            <div className="relative block h-full w-full">
              {currentSlideElement}
              {!renderDots && renderSlideIndexes()}
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
      {renderDots && renderSlideDots()}
    </div>
  )
}

export default Carousel
